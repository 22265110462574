import React from "react";
import NotFoundIcon from "resources/img/icons/not_found_icon.svg";
import Header from "../reusable/Header";

const ApplicationError = () => (
  <div>
    <Header />
    <div className="newsmile-container__login">
      <div className="smile-login__container">
        <div className="smile-login__icon-container">
          <img
            className="smile-login__icon"
            src={NotFoundIcon}
            alt="smile logo"
          />
          <h1>Application Error</h1>
          <p>
            Sorry, an error occurred. Please try again or contact Smile ID if
            the issue persists.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default ApplicationError;

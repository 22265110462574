import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, Link } from "react-router-dom";

import { receiveToken } from "actions/session";
import Header from "components/reusable/Header";
import { Loader } from "components/reusable/Loader";
import { useLogIn } from "mutations";
import SmileLockAnimated from "resources/img/brand/lock_loco_animated_80x80.gif";
import SmileLock from "resources/img/brand/smile-id__symbol--96x96.svg";
import { initiateSSO, callbackSSO } from "util/api_util";

function LoginPage(props) {
  const [email, setEmail] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(props.errors);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const params = new URLSearchParams(useLocation().search);
  const dispatch = useDispatch();
  const login = useLogIn({
    onSuccess: (data) => {
      dispatch(receiveToken(data));
    },
  });
  // "code" is a searchParam when we the user is redirected back to this page by
  // by our SSO provider.
  const [code, setCode] = useState(() => params.get("code") || null);
  const [isSSOLoading, setIsSSOLoading] = useState(false);
  // Get the history so that if after an SSO redirect, if an error occurs,
  // we can remove the code from the queryParams and allow the user to start
  // the login flow from the beginning.
  const history = useHistory();

  function followSSORedirect() {
    window.location.assign(redirectUrl);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // First step in the flow submits only the email
    if (!email) {
      setEmail(emailInput);
      return;
    }

    // If the form is submitted by hitting enter on the email field
    if (redirectUrl && !isSSOLoading && !password && email === emailInput) {
      followSSORedirect();
      return;
    }

    if (password) {
      login.mutate(
        { email: emailInput, password },
        {
          onError: (error) => {
            if (error.response?.data) {
              setError(error.response.data.errors[0]);
            }
          },
        },
      );
    }
  };

  const msgStyle = classNames("message message--login", {
    "message--error": props.errors || error,
  });
  const inputStyle = classNames("input--line", { danger: props.errors });

  useEffect(() => {
    if (email && !code) {
      setIsSSOLoading(true);
      initiateSSO({ email })
        .then((resp) => {
          if (resp.error) {
            setError(resp.error);
            return;
          }
          setRedirectUrl(resp.redirect_url);
          setError(null);
        })
        .finally(() => {
          setIsSSOLoading(false);
        });
    }
  }, [email]);

  // This is a hack to get around the potential of having this hook run multiple
  // times. The useEffect hook runs when a render is complete whether or not the
  // requirement exists. Therefore, we need a way to know if we have already
  // made this call to the backend so we can no-op.
  let callbackPromise;
  useEffect(() => {
    // If the SSO provider has redirected back our app with "code" as a searchParam
    // we need to call to the backend to verify and get the auth_token for use in
    // our system.
    if (callbackPromise) {
      return;
    }
    if (code && !error && !isSSOLoading) {
      const state = params.get("state");
      setIsSSOLoading(true);
      callbackSSO({ code, email: state.split("=")[1] })
        .then((resp) => {
          if (resp.error) {
            setError(resp.error);
            setCode(null);
            // Remove the "code" param from the url so we can revert to the beginning of
            // the login flow.
            params.delete("code");
            history.replace({
              search: params.toString(),
            });
            callbackPromise = undefined;
          }
          if (resp.auth_token) {
            localStorage.setItem("token", resp.auth_token);
            history.push("/");
          }
        })
        .finally(() => {
          setIsSSOLoading(false);
        });
    }
  }, [code]);

  if (code && !error) {
    return (
      <>
        <Header envSlider={false} login />
        <div className="newsmile-container__login">
          <div className="smile-login__container">
            <div className="smile-login__icon-container">
              <img
                className="smile-login__icon"
                src={SmileLock}
                alt="smile logo"
              />
            </div>
            <div className="smile-login__heading">Welcome to Smile ID</div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header envSlider={false} />
      <div className="newsmile-container__login">
        <div className="smile-login__container">
          <div className="smile-login__icon-container">
            <img
              className="smile-login__icon"
              src={isSSOLoading ? SmileLockAnimated : SmileLock}
              alt="smile logo"
            />
          </div>
          <div className="smile-login__heading">Welcome to Smile ID</div>
          {(error || props.errors) && (
            <div
              className={msgStyle}
              style={{ display: props.errors || error ? "block" : "none" }}
            >
              {props.errors || error}
            </div>
          )}

          <form
            className="smile-form smile-form--login"
            id="new_account"
            onSubmit={handleSubmit}
          >
            <div className="field">
              <br />
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                className={inputStyle}
                onChange={(e) => {
                  setEmailInput(e.target.value);
                }}
                onBlur={() => {
                  if (email && emailInput && email !== emailInput) {
                    setEmail(emailInput);
                  }
                }}
                required
                value={emailInput}
              />
            </div>
            {!email && (
              <div className="button-container--center">
                <button
                  className="btn btn-primary smile-login__button"
                  type="submit"
                  data-testid="continue-button"
                >
                  {isSSOLoading && (
                    <>
                      <Loader />
                      &nbsp;&nbsp;
                    </>
                  )}
                  Continue
                </button>
              </div>
            )}
            {redirectUrl && (
              <div className="login__signup-container button-container--center">
                <br />
                <button
                  disabled={isSSOLoading || email !== emailInput}
                  className="btn btn-primary smile-login__button"
                  type="button"
                  data-testid="sso-button"
                  onClick={followSSORedirect}
                >
                  {(isSSOLoading || email !== emailInput) && (
                    <>
                      <Loader />
                      &nbsp;&nbsp;
                    </>
                  )}
                  Sign In With SSO
                </button>
                <br />
                <p>Or</p>
                <br />
              </div>
            )}
            {email && !isSSOLoading && (
              <div>
                <div className="field">
                  <br />
                  <label htmlFor="password">Password</label>
                  <input
                    data-testid="password-input"
                    type="password"
                    id="password"
                    name="password"
                    className={inputStyle}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    required
                    value={password}
                  />
                </div>
                <div className="login__forgot-password-container button-container--right">
                  <Link
                    className="login__forgot-password"
                    to="/forgot_password"
                    data-testid="forgot-password-link"
                  >
                    Forgot password?
                  </Link>
                </div>
                <br />
                <div className="button-container--center">
                  <button
                    disabled={login.isPending}
                    className="btn btn-primary smile-login__button"
                    type="submit"
                    onClick={(e) => {
                      if (!password) {
                        e.preventDefault();
                      }
                    }}
                    data-testid="login-button"
                  >
                    {login.isPending && (
                      <>
                        <Loader />
                        &nbsp;&nbsp;
                      </>
                    )}
                    Sign In
                  </button>
                </div>
              </div>
            )}
            <div className="login__signup-container button-container--center">
              <span>Don’t have an account? </span>
              <a
                className="login__signup"
                href="https://usesmileid.com/talk-to-an-expert"
              >
                Sign Up
              </a>
            </div>
            <div className="fixed-dots--bottom-across" />
          </form>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
